.table-container {
    height: 400px; /* Set the desired height for the table container */
    overflow: auto;
  }
  
  .scrollable-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .scrollable-table th,
  .scrollable-table td {
    border: 1px solid #ccc;
    padding: 8px;
  }
  