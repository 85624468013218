.filelabel2 {
    width: 30%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #B0B0B0;
    border-radius: 10px;
    display: block;
    padding: 5px;
    transition: border 300ms ease;
    cursor: pointer;
    text-align: center;
    margin: 10px;
    color: #474747;
}

.filelabel2 i {
    display: block;
    font-size: 30px;
    padding-bottom: 5px;
}

.filelabel2 i,
.filelabel2 .title2 {
    padding-top: 15px;
    margin-top: 10px;
    color: #474747;
    transition: 200ms color;
}

.filelabel2:hover i,
.filelabel2:hover .title2 {
    color: #1665c4;
}

#FileInput2 {
    display: none;
}