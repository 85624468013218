

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.header{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100px;
}
/* .maincontain{
  margin-left: 16%;
} */
@media screen and (max-width: 1280px) {
  .maincontain{
    margin-left: 22% !important;
    margin-top: 1.5%;
  }
  .head{
    margin-left: 22% !important;
  }
  .maincon{
    margin-left: 20% !important;
  }
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
    
  }
  to {
    transform: rotate(360deg);
  }
}
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
::placeholder {
  color: #B1AFC8 !important;
}
.list-group-item{
  border: none;
  text-decoration: none;
}


.side_list {
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: #000 !important;
  border-left: 3px solid #fff;
  text-decoration: none;
}

.side_list:hover {
  background-color: #F8F5FF !important;
  border-left: 3px solid #8147E7;
  text-decoration: none;
  color: #8147E7 !important;
  
}

.list-group-item.active {
  z-index: 2;
  /* color: var(--bs-list-group-active-color); */
  /* background-color: var(--bs-list-group-active-bg); */
  border-color: #8147E7 !important;
  color: #8147E7 !important;
  background-color: #F8F5FF !important;
  animation-duration: 4s;
}
.header{
  width: 150px;
}
.card1{
  background-image: linear-gradient(to right, #8147e7, #7a3ced, #712ff3, #681ef9, #5c00ff);
  color: #fff;
  
  border:none;
  border-radius: 10px;
}
p 
{ 
   padding:0px; 
   margin:0px; 
} 
h1 h2 h3 h4 h5 h5{
  padding:0px; 
   margin:0px; 
}
.card2{
  border: 1px solid #B1AFC8;
  border-radius: 10px;
}
.value{
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #40997E;
}

.holder {
  height: 100%;
  overflow-y: hidden;
  width: 100%;
}
.tab-1:focus{
  border-bottom: 2px solid #40997e;
}
.tab-2:focus{
  border-bottom: 2px solid #F03838;
}
.tab-3:focus{
  border-bottom: 2px solid #e2a925;
}


.apexcharts-zoom-icon, .apexcharts-reset-icon, .apexcharts-menu-icon {
    transform: scale(0.85);
}

.apexcharts-reset-icon {
    margin-left: 5px;
}

.apexcharts-zoom-icon, .apexcharts-zoomin-icon, .apexcharts-zoomout-icon, .apexcharts-reset-icon, .apexcharts-pan-icon, .apexcharts-selection-icon, .apexcharts-menu-icon, .apexcharts-toolbar-custom-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    line-height: 24px;
    color: #6E8192;
    text-align: center;
    display: none;
}
.chart-btn{  
  background-color: #F3F2F8;
  color:#54536E;
  font-size: 16px;
  border-radius: 5px;
  border: none;
}
.chart-btn:focus{
  background-color: #8147E7;
  color: #fff;
}
.download-chart-btn{
  background-color: #fff;
  color:#54536E;
  font-size: 16px;
  border-radius: 5px;
  border: none;
}
.css-1aquho2-MuiTabs-indicator {
  position: absolute;
  height: 1px !important;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #000 !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #000 !important;
}
.maill:focus{
  background-color: #fff;
}
.maill:hover{
  color: #000 !important;
}
.css-19kzrtu {
  padding: 0px !important;
}
.bar3 {
  height: 300px;
  max-height: 100%;
  overflow-y: auto !important;
}
div::-webkit-scrollbar {
  display: none;
}
.bar4 {
  height: 500px;
  max-height: 100%;
  overflow-y: auto !important;
}
.bar6 {
  height: 600px;
  max-height: 100%;
  overflow-y: auto !important;
}
.bar5{
  height: 300px;
  max-height: 100%;
  overflow-y: auto !important;
}
.bar7{
  height: 341px;
  max-height: 100%;
  overflow-y: auto !important;
}
.bar8{
  height: 190px;
  width: 100%;
  max-width: 100% !important;
  max-height: 100%;
  overflow-x: auto !important;
}
/* .bar8::-webkit-scrollbar {
  display: block;
  scrollbar-color: inherit;
  background: red;
  width: 20px;
} */
/* .bar8::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
  width: 100%;
  background-color: green;
} */

.btn {
  /* just for this demo. */
  
  margin-top: 5px;
}

.btn-arrow-right,
.btn-arrow-left {
  position: relative;
  padding-left: 18px;
  padding-right: 18px;
}

.btn-arrow-right {
  padding-left: 36px;
}

.btn-arrow-left {
  padding-right: 36px;
}

.btn-arrow-right:before,
.btn-arrow-right:after,
.btn-arrow-left:before,
.btn-arrow-left:after {
  /* make two squares (before and after), looking similar to the button */
  
  content: "";
  position: absolute;
  top: 5px;
  /* move it down because of rounded corners */
  
  width: 25px;
  /* same as height */
  
  height: 26px;
  /* button_outer_height / sqrt(2) */
  
  background: inherit;
  /* use parent background */
  
  border: inherit;
  /* use parent border */
  
  border-left-color: transparent;
  /* hide left border */
  
  border-bottom-color: transparent;
  /* hide bottom border */
  
  border-radius: 0px 4px 0px 0px;
  /* round arrow corner, the shorthand property doesn't accept "inherit" so it is set to 4px */
  
  -webkit-border-radius: 0px 4px 0px 0px;
  -moz-border-radius: 0px 4px 0px 0px;
}

.btn-arrow-right:before,
.btn-arrow-right:after {
  transform: rotate(45deg);
  /* rotate right arrow squares 45 deg to point right */
  
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.btn-arrow-left:before,
.btn-arrow-left:after {
  transform: rotate(225deg);
  /* rotate left arrow squares 225 deg to point left */
  
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
}

.btn-arrow-right:before,
.btn-arrow-left:before {
  /* align the "before" square to the left */
  
  left: -11px;
}

.btn-arrow-right:after,
.btn-arrow-left:after {
  /* align the "after" square to the right */
  
  right: -11px;
}

.btn-arrow-right:after,
.btn-arrow-left:before {
  /* bring arrow pointers to front */
  
  z-index: 1;
}

.btn-arrow-right:before,
.btn-arrow-left:after {
  /* hide arrow tails background */
  
  background-color: white;
}

input::placeholder {
  color: #BDBDBD !important;
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  width: 100%;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 1.0em !important;
  color: #BDBDBD;
  font-size: 14px;
  padding: 8px 12px;
  background-color: transparent;
  border: 1px solid #BDBDBD;
  /* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25); */
}

.file-input__label svg {
  height: 16px;
  margin-left: 30px !important;
}

.mail:hover{
  transition: transform .2s;
}

.mail:hover{
  color: red !important;
  transform: scale(1.4);
  transition: transform .2s;
}

.dropbtn {
  background-color: transparent;
  color: #000;
  /* padding: 20px; */
  font-size: 1.2em;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  padding: 0px;
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 80px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 0px 0px;
  text-decoration: none;
  display: block;
  font-size: 0.7rem;
}

/* .dropdown-content a:hover {background-color: #ddd;} */

.dropdown:hover .dropdown-content {display: block;}
/* 
.dropdown:hover .dropbtn {background-color: #3e8e41;} */
.barform{
  height: 350px;
  max-height: 100%;
  overflow-y: auto !important;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  border: 0;
  border-radius: 45px;
  opacity: .5;

}
.supportmail:focus{
  background-color: #fff !important;
}
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #b5fac7;
}

.pages {
  color: #316685;
  text-align: center;
  font-size: calc(1.5rem + 2vw);
  margin-top: 10%;
  
 
}

.navbar {
  background-color: #1f5156;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.main-head{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:rgba(0,0,0,0.3);
}

.nav-logo {
  color: #f5b921;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #ffdd40;
}

.nav-item.active {
  color: #ffdd40;
  border: 1px solid #ffdd40;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1f5156;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #ffdd40;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #ffdd40;
  }
  
}
.clsbtn{
  display: none !important;
}
@media screen and (max-width: 992px) {
  .sidehide{
    display: none !important;
  }
  .maincontain{
    margin-left: 0% !important;
    width: 100% !important;
  }
  .clsbtn{
    display: block !important;
  }
}

.react-calendar {
  width: 100% !important;
  height: 80vh !important;
  background: transparent !important;
  border: 1px solid transparent !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  font-size: 20px;

}
 /* *{
    flex-grow: 0 !important;
} */
/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  border:none;
} */

/* .stp:hover{
  background-color: red;
} */
/* 
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
} */

.gg:hover{
  background-color: transparent;
   
}
.file > label {
 
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
}
.file > input[type='file'] {
  display: none
}



.chat-app .people-list {
  width: 280px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  z-index: 7
}

.chat-app .chat {
  margin-left: 280px;
  border-left: 1px solid #eaeaea
}

.people-list {
  -moz-transition: .5s;
  -o-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s
}

.people-list .chat-list li {
  padding: 10px 15px;
  list-style: none;
  border-radius: 3px
}

.people-list .chat-list li:hover {
  background: #efefef;
  cursor: pointer
}

.people-list .chat-list li.active {
  background: #efefef
}

.people-list .chat-list li .name {
  font-size: 15px
}

.people-list .chat-list img {
  width: 45px;
  border-radius: 50%
}

.people-list img {
  float: left;
  border-radius: 50%
}

.people-list .about {
  float: left;
  padding-left: 8px
}

.people-list .status {
  color: #999;
  font-size: 13px
}

.chat .chat-header {
  padding: 15px 20px;
  border-bottom: 2px solid #f4f7f6
}

.chat .chat-header img {
  float: left;
  border-radius: 40px;
  width: 40px
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px
}

.chat .chat-history {
  padding: 20px;
  border-bottom: 2px solid #fff
}

.chat .chat-history ul {
  padding: 0
}

.chat .chat-history ul li {
  list-style: none;
  margin-bottom: 30px
}

.chat .chat-history ul li:last-child {
  margin-bottom: 0px
}

.chat .chat-history .message-data {
  margin-bottom: 15px
}

.chat .chat-history .message-data img {
  border-radius: 40px;
  width: 40px
}

.chat .chat-history .message-data-time {
  color: #434651;
  padding-left: 6px
}

.chat .chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative
}

.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px
}

.chat .chat-history .my-message {
  background: #efefef
}

.chat .chat-history .my-message:after {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #efefef;
  border-width: 10px;
  margin-left: -10px
}

.chat .chat-history .other-message {
  background: #e8f1f3;
  text-align: right
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  left: 93%
}

.chat .chat-message {
  padding: 20px
}

.online,
.offline,
.me {
  margin-right: 2px;
  font-size: 8px;
  vertical-align: middle
}

.online {
  color: #86c541
}

.offline {
  color: #e47297
}

.me {
  color: #1d8ecd
}

.float-right {
  float: right
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0
}

@media only screen and (max-width: 767px) {
  .chat-app .people-list {
      height: 465px;
      width: 100%;
      overflow-x: auto;
      background: #fff;
      left: -400px;
      display: none
  }
  .chat-app .people-list.open {
      left: 0
  }
  .chat-app .chat {
      margin: 0
  }
  .chat-app .chat .chat-header {
      border-radius: 0.55rem 0.55rem 0 0
  }
  .chat-app .chat-history {
      height: 300px;
      overflow-x: auto
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .chat-app .chat-list {
      height: 650px;
      overflow-x: auto
  }
  .chat-app .chat-history {
      height: 600px;
      overflow-x: auto
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .chat-app .chat-list {
      height: 480px;
      overflow-x: auto
  }
  .chat-app .chat-history {
      height: calc(100vh - 350px);
      overflow-x: auto
  }
}

.mainLoginInput::-webkit-input-placeholder { 
  font-family: FontAwesome;
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  color: hsl(9, 40%, 60%);
  }




  .livel {
    padding: 0 0.7em 1px 0;
    display: inline-block;
  }
  
  .livel_icon {
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
    animation: updateAnime 2s infinite;
    will-change: transform, opacity;
    -webkit-animation: updateAnime 2s infinite;
}
  
  .livel_icon:after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 0.9em;
    height: 0.9em;
    min-width: 0.9em;
    min-height: 0.9em;
    background-color:green;
  }
  
  @keyframes updateAnime {
    0%  {opacity:0; transform: scale(0.9);}
    50% {opacity:1; transform: scale(1);}
    to  {opacity:0; transform: scale(0.9); }
  }

  

  .datepAdd{
    background-color: #E0E0E0;
    width: 130px;
    font-size: 17px;
    margin-top: -12px;
    outline: 0;
  }


  .datep{
    background-color: #EDEDF5;
    width: 130px;
    font-size: 15px;
    margin-top: 3px;
    
  
  }
  .datepi{
    background-color: #E0E0E0;
  
    width: 120px;
    font-size: 17px;
    margin-top: 12px;
  }
  
  .datepii{
    background-color: transparent;
  
    width: 170px;
    font-size: 20px;
    margin-top: 8px;
  }
  .datePicker{
    background-color: #EDEDF5;
  }
  .react-date-picker__calendar .react-calendar {
    background-color: #EDEDF5 !important;
    height: auto !important;
  }

  /* .card-sadows{
    box-shadow: 0px 0px 14px 0px rgba(0,0,0,1);
    -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,1);
  } */

   .its{
    border-left: 1px solid rgb(177, 175, 200);;

 }
   .sid{
    border-right: 1px solid rgb(177, 175, 200);;

 }

 .box{
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
 }
