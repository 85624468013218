.Main {
    background-color: #EFEFEF;
    width: "25%";
    border: 0;
    color: #8A8A8A;
    outline: none;
    margin-top: 6px;
    margin-left: 10px;

}

.Main1 {

    width: "25%";
    border: 0;
    color: #8A8A8A;
    outline: none;
    margin-top: 6px;
    margin-left: 10px;

}